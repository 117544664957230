import React, { useCallback, useState } from "react";
import "./styles.scss";
import ExclusiveCategoriesDropDown from "../controls/ExclusiveCategoriesDropDown";
import getMenuItemExclusiveCategories from "../../../domain/navigation/queries/getMenuItemExclusiveCategories";
import DateRangeControl from "./components/DateRangeControl";
import useChangeExclusiveCategoryCallback from "../../shared/hooks/useChangeExclusiveCategoryCallback";
import { MenuItem } from "../../../lib/types";

type Props = {
  menuItem: MenuItem;
};

const EventListHeader = ({ menuItem }: Props) => {
  const exclusiveCategories = getMenuItemExclusiveCategories(menuItem);
  const hasExclusiveCategories = exclusiveCategories.length > 0;
  const [selectedExclusiveCategoryId, setSelectedExclusiveCategoryId] =
    useState(null);

  const onChange = useChangeExclusiveCategoryCallback(
    setSelectedExclusiveCategoryId,
  );

  const dateRangeControlInitializeCalllback = useCallback(
    (dateRangeControl) => {
      VisitWidget.dateRangeControls[menuItem.id] = dateRangeControl;
    },
    [menuItem],
  );

  return (
    <div className="EventListHeader">
      {hasExclusiveCategories && (
        <ExclusiveCategoriesDropDown
          categories={exclusiveCategories}
          onChange={onChange}
          selectedExclusiveCategoryId={selectedExclusiveCategoryId}
        />
      )}

      <DateRangeControl
        containerSelector={`#sidebar_Event${menuItem.id}`}
        shouldAlignRight={hasExclusiveCategories}
        onInitialize={dateRangeControlInitializeCalllback}
      />
    </div>
  );
};

export default EventListHeader;
