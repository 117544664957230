import { getFragmentValue } from "../../../lib/url";
import { getCookie, setCookie, CookieKeys } from "../../../lib/cookies";
import { setAuth } from "../../visitWidgetApi";
import {
  refreshSessionError,
  refreshSessionFetch,
  refreshSessionSuccess,
} from "../authActionTypes";
import { initializeUser } from "../helpers/initializeUser";
import { initializeAnonymousUser } from "../helpers/initializeAnonymousUser";

const getToken = async () => {
  let authToken;
  const oauthFlowToken = getFragmentValue("auth_token");
  if (oauthFlowToken !== undefined) {
    authToken = oauthFlowToken;
    await setCookie(CookieKeys.authToken, authToken);
    window.history.pushState({}, "", "/");
  } else {
    authToken = await getCookie<string | undefined>(
      CookieKeys.authToken,
      undefined,
    );
  }

  return authToken;
};

/*
 * On app initialization set necessary state based on cookies.
 */
const refreshSessionAction = () => async (dispatch) => {
  try {
    dispatch(refreshSessionFetch());

    const authToken = await getToken();

    if (!authToken) {
      // user doesn't need token, signifies logged out
      await initializeAnonymousUser(dispatch);
      dispatch(refreshSessionSuccess());
      return;
    }

    setAuth(authToken);
    const { user } = await initializeUser(authToken, dispatch);

    dispatch(refreshSessionSuccess(user, authToken));

    // BRIDGE: hybrid action (needed for password reset)
    VisitWidget.Modal.close($(".modal"));
  } catch (error) {
    dispatch(refreshSessionError(error));
  }
};

export { refreshSessionAction };
