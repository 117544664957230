/* eslint-disable react/jsx-no-bind */
/* eslint-disable react-func/max-lines-per-function */
/* eslint-disable complexity */
/* eslint-disable max-lines */
/* eslint-disable max-statements */
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Button } from "src/components/Button";
import { Form } from "src/components/form/Form";
import { TextInput } from "src/components/TextInput";
import { Toggle } from "src/components/Toggle";
import {
  Modal,
  ModalAction,
  ModalContent,
  ModalTitle,
} from "src/components/Modal";
import { Modal as AntModal } from "antd";
import {
  getChallengeSubmissionConfig,
  updateChallengeSubmissionConfigs,
} from "src/store/visitWidgetApi";
import {
  ChallengeSubmissionConfigDto,
  CustomInputDto,
} from "src/store/apiTypes";
import { CustomInputsList } from "./CustomInputsList";
import { mapFormToUpsertRequest, mapDtoToForm } from "./helpers";
import translate from "src/lib/queries/translate";
// import { isDefined } from "../../../legacy/lib/util";
import "./ChallengeSubmissionConfig.scss";

// eslint-disable-next-line no-shadow
enum ChallengeSubmissionConfigTestIds {
  completionMessageField = "challenge-submission-config-completion-message-field",
}

interface ChallengeSubmissionConfigScreenProps {
  challengeId: string | number;
}

interface ChallengeSubmissionConfigFormState {
  formEnabled: boolean;
  completionMessage: string;
  customInputs: CustomInputDto[];
}

// eslint-disable-next-line complexity
const ChallengeSubmissionConfig: FunctionComponent<
  ChallengeSubmissionConfigScreenProps
> = ({ challengeId }) => {
  const [state, setState] = useState<{
    challengeSubmissionConfigStatus: "idle" | "fetching" | "error";
    saveStatus: "idle" | "fetching" | "error";
    isModalOpen: boolean;
    challengeSubmissionConfig?: ChallengeSubmissionConfigDto;
  }>({
    challengeSubmissionConfigStatus: "fetching",
    saveStatus: "idle",
    isModalOpen: false,
    challengeSubmissionConfig: {} as ChallengeSubmissionConfigDto,
  });
  const [confirmModal, contextHolder] = AntModal.useModal();

  useEffect(() => {
    (async () => {
      try {
        if (
          state.isModalOpen &&
          state.challengeSubmissionConfig?.challenge_id !== challengeId
        ) {
          const existingConfig = await getChallengeSubmissionConfig(
            challengeId,
          );
          setState((current) => ({
            ...current,
            challengeSubmissionConfigStatus: "idle",
            challengeSubmissionConfig: existingConfig,
          }));
        }
      } catch (error) {
        setState((current) => ({
          ...current,
          challengeSubmissionConfigStatus: "error",
        }));
      }
    })();
  }, [
    challengeId,
    state.challengeSubmissionConfig?.challenge_id,
    state.isModalOpen,
  ]);

  const [form] = Form.useForm<ChallengeSubmissionConfigFormState>();

  const handleChallengeSubmissionConfigPress = useCallback(async () => {
    setState((current) => ({ ...current, isModalOpen: !current.isModalOpen }));
  }, []);

  const handleModalClose = useCallback(() => {
    confirmAbandonChanges();
  }, []);

  const abandonChanges = () => {
    form.resetFields();
    setState((current) => ({
      ...current,
      saveStatus: "idle",
      isModalOpen: false,
      challengeSubmissionConfig: {} as any,
    }));
  };

  const confirmAbandonChanges = () => {
    const isTouched =
      form.isFieldTouched("customInputs") ||
      form.isFieldTouched("formEnabled") ||
      form.isFieldTouched("completionMessage");
    if (isTouched) {
      confirmModal.confirm({
        title: "Leave without saving?",
        content: "Changes you made will be lost",
        okText: "Leave",
        onOk() {
          abandonChanges();
        },
      });
    } else {
      abandonChanges();
    }
  };

  const handleSubmit = async (
    formValues: ChallengeSubmissionConfigFormState,
  ) => {
    setState((current) => ({ ...current, saveStatus: "fetching" }));
    try {
      const upsertValues = mapFormToUpsertRequest(formValues);
      upsertValues.challenge_id = state.challengeSubmissionConfig.challenge_id;
      const updatedChallengeSubmissionConfig =
        await updateChallengeSubmissionConfigs(challengeId, upsertValues);
      if (updatedChallengeSubmissionConfig.error !== undefined) {
        setState((current) => ({
          ...current,
          saveStatus: "error",
        }));
      } else {
        setState((current) => ({
          ...current,
          saveStatus: "idle",
          isModalOpen: false,
          challengeSubmissionConfig: {} as any,
        }));
      }
    } catch (e) {
      setState((current) => ({ ...current, saveStatus: "error" }));
    }
  };

  const resetMessageToDefault = useCallback(() => {
    form.setFieldValue(
      "completionMessage",
      translate("challenge_submission_config.completion_message_template", {
        challengeName: state.challengeSubmissionConfig?.challenge_name,
      }),
    );
  }, [state.challengeSubmissionConfig?.challenge_name]);

  return (
    <>
      {contextHolder}
      <div className="touch-control fl">
        <div className="btn icon_btn">
          <div className="content" style={{ color: "#fff" }}>
            <Button
              type="primary"
              onPress={handleChallengeSubmissionConfigPress}>
              {translate("challenge_submission_config.submission_form_button")}
            </Button>
          </div>
        </div>
      </div>

      <Modal open={state.isModalOpen} onClose={handleModalClose} width={720}>
        <ModalTitle fontSize="large">
          {translate("challenge_submission_config.modal_title")}
        </ModalTitle>
        <ModalContent>
          {state.isModalOpen &&
            state.challengeSubmissionConfigStatus === "idle" && (
              <Form
                form={form}
                name="challengeSubmissionConfig"
                onFinish={handleSubmit}
                disabled={state.saveStatus === "fetching"}
                layout="vertical"
                initialValues={mapDtoToForm(state.challengeSubmissionConfig)}>
                {state.saveStatus === "error" && (
                  <div className="error">
                    An error occurred during the save.
                  </div>
                )}

                <div className="challenge-submission-config-form-row">
                  <div className="challenge-submission-config-form-row-column title-regular">
                    {translate("challenge_submission_config.message_content")}
                  </div>
                  <div className="challenge-submission-config-form-row-column">
                    <Button type="linkSmall" onPress={resetMessageToDefault}>
                      {translate(
                        "challenge_submission_config.reset_to_default",
                      )}
                    </Button>
                  </div>
                </div>

                <div className="challenge-submission-config-form-row">
                  <TextInput
                    name="completionMessage"
                    size="large"
                    form={form}
                    disabled={state.saveStatus === "fetching"}
                    multiline={true}
                    numberOfLines={4}
                    placeholder={translate(
                      "challenge_submission_config.completion_message_template",
                      {
                        challengeName:
                          state.challengeSubmissionConfig?.challenge_name,
                      },
                    )}
                    testID={
                      ChallengeSubmissionConfigTestIds.completionMessageField
                    }
                  />
                </div>

                <div className="challenge-submission-config-form-row title-regular">
                  {translate("challenge_submission_config.form_fields")}
                </div>

                <div className="challenge-submission-config-form-row">
                  <CustomInputsList
                    mode={state.saveStatus === "fetching" ? "view" : "edit"}
                    form={form}
                  />
                </div>

                <div className="challenge-submission-config-form-row">
                  <div
                    className="challenge-submission-config-form-row-column"
                    style={{ paddingRight: "16px" }}>
                    <Form.Item name="formEnabled">
                      <Toggle name="formEnabled" form={form} />
                    </Form.Item>
                  </div>
                </div>
              </Form>
            )}
        </ModalContent>

        <ModalAction position="right">
          <Button
            type="outline"
            onPress={handleModalClose}
            disabled={state.saveStatus === "fetching"}>
            {translate("form_controls.cancel_button")}
          </Button>
        </ModalAction>

        <ModalAction position="right">
          <Button
            type="primary"
            disabled={state.saveStatus === "fetching"}
            onPress={() => {
              form.submit();
            }}>
            Save
          </Button>
        </ModalAction>
      </Modal>
    </>
  );
};

export {
  ChallengeSubmissionConfig,
  ChallengeSubmissionConfigFormState,
  ChallengeSubmissionConfigScreenProps,
};
