/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { CSSProperties, FunctionComponent } from "react";
import { Form as AntForm, FormInstance } from "antd";
import dayjs from "dayjs";
import type { Rule } from "antd/es/form";
import "./styles.scss";

interface FormProps {
  initialValues: Record<string, any>;
  disabled?: boolean;
  onFinish: (values: any) => void;
  name?: string;
  form: FormInstance<any>;
  onValuesChange?: (newValues: Record<any, any>) => void;
  layout?: "vertical" | "horizontal";
}

interface FormItemProps {
  name?: string | number | (string | number)[];
  label?: string;
  rules?: Rule[];
  extra?: string;
  noStyle?: boolean;
  hidden?: boolean;
  style?: CSSProperties;
  valuePropName?: string;
}

const FormBase: FunctionComponent<FormProps> = ({ children, ...rest }) => {
  return (
    <AntForm style={{ width: "100%" }} requiredMark={false} {...rest}>
      {children}
    </AntForm>
  );
};

const Form = FormBase as FunctionComponent<FormProps> & {
  Item: FunctionComponent<FormItemProps>;
} & {
  useForm: <Values = any>(
    form?: FormInstance<Values>,
  ) => [FormInstance<Values>];
  useWatch: (
    name: string | number | (string | number)[],
    form: FormInstance<any>,
  ) => string | number | boolean | [dayjs.Dayjs, dayjs.Dayjs];
};

Form.Item = AntForm.Item as FunctionComponent<FormItemProps>;
Form.useForm = AntForm.useForm;
Form.useWatch = AntForm.useWatch;

export { Form, FormProps, FormItemProps };
