/* eslint-disable max-lines */
import { User } from "../apiTypes";

// eslint-disable-next-line no-shadow
export enum AuthActionTypes {
  LOGIN_SUCCESS = "LOGIN_SUCCESS",
  LOGIN_ERROR = "LOGIN_ERROR",
  LOGIN_FETCH = "LOGIN_FETCH",
  LOGOUT_SUCCESS = "LOGOUT_SUCCESS",
  LOGOUT_ERROR = "LOGOUT_ERROR",
  LOGOUT_FETCH = "LOGOUT_FETCH",
  REGISTER_SUCCESS = "REGISTER_SUCCESS",
  REGISTER_ERROR = "REGISTER_ERROR",
  REGISTER_FETCH = "REGISTER_FETCH",
  REFRESH_SESSION_FETCH = "REFRESH_SESSION_FETCH",
  REFRESH_SESSION_ERROR = "REFRESH_SESSION_ERROR",
  REFRESH_SESSION_SUCCESS = "REFRESH_SESSION_SUCCESS",
  RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR",
  RESET_PASSWORD_FETCH = "RESET_PASSWORD_FETCH",
  UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS",
  UPDATE_PASSWORD_ERROR = "UPDATE_PASSWORD_ERROR",
  UPDATE_PASSWORD_FETCH = "UPDATE_PASSWORD_FETCH",
  SET_PLAN_SUCCESS = "SET_PLAN_SUCCESS",
}

export interface LoginFetchAction {
  type: AuthActionTypes.LOGIN_FETCH;
  payload: {
    loginStatus: "fetch";
  };
}

export interface LoginSuccessAction {
  type: AuthActionTypes.LOGIN_SUCCESS;
  payload: {
    loginStatus: "success";
    token: string;
    user: User;
  };
}

export interface LoginErrorAction {
  type: AuthActionTypes.LOGIN_ERROR;
  payload: {
    loginStatus: "error";
    loginStatusError: string;
  };
}

export interface LogoutFetchAction {
  type: AuthActionTypes.LOGOUT_FETCH;
  payload: {
    logoutStatus: "fetch";
  };
}

export interface LogoutSuccessAction {
  type: AuthActionTypes.LOGOUT_SUCCESS;
  payload: {
    logoutStatus: "success";
    user: undefined;
    token: undefined;
    isAdminMode: false;
  };
}

export interface LogoutErrorAction {
  type: AuthActionTypes.LOGOUT_ERROR;
  payload: {
    logoutStatus: "error";
    logoutStatusError: string;
  };
}

export interface RefreshSessionFetchAction {
  type: AuthActionTypes.REFRESH_SESSION_FETCH;
  payload: {
    refreshSessionStatus: "fetch";
  };
}

export interface RefreshSessionSuccessAction {
  type: AuthActionTypes.REFRESH_SESSION_SUCCESS;
  payload: {
    token?: string;
    user?: User;
    refreshSessionStatus: "success";
  };
}

export interface RefreshSessionErrorAction {
  type: AuthActionTypes.REFRESH_SESSION_ERROR;
  payload: {
    refreshSessionStatusError: string;
    refreshSessionStatus: "error";
  };
}

export interface SetPlanSuccessAction {
  type: AuthActionTypes.SET_PLAN_SUCCESS;
  payload: {
    planId: number;
  };
}

export interface RegisterFetchAction {
  type: AuthActionTypes.REGISTER_FETCH;
  payload: {
    registerStatus: "fetch";
  };
}

export interface RegisterSuccessAction {
  type: AuthActionTypes.REGISTER_SUCCESS;
  payload: {
    registerStatus: "success";
    token: string;
    user: User;
  };
}

export interface RegisterErrorAction {
  type: AuthActionTypes.REGISTER_ERROR;
  payload: {
    registerStatus: "error";
    registerStatusError: string;
  };
}

export interface ResetPasswordFetchAction {
  type: AuthActionTypes.RESET_PASSWORD_FETCH;
  payload: {
    resetPasswordStatus: "fetch";
  };
}

export interface ResetPasswordSuccessAction {
  type: AuthActionTypes.RESET_PASSWORD_SUCCESS;
  payload: {
    resetPasswordStatus: "success";
  };
}

export interface ResetPasswordErrorAction {
  type: AuthActionTypes.RESET_PASSWORD_ERROR;
  payload: {
    resetPasswordStatus: "error";
    resetPasswordStatusError: string;
  };
}

export interface UpdatePasswordFetchAction {
  type: AuthActionTypes.UPDATE_PASSWORD_FETCH;
  payload: {
    updatePasswordStatus: "fetch";
  };
}

export interface UpdatePasswordSuccessAction {
  type: AuthActionTypes.UPDATE_PASSWORD_SUCCESS;
  payload: {
    updatePasswordStatus: "success";
  };
}

export interface UpdatePasswordErrorAction {
  type: AuthActionTypes.UPDATE_PASSWORD_ERROR;
  payload: {
    updatePasswordStatus: "error";
    updatePasswordStatusError: string;
  };
}

export const loginFetch = (): LoginFetchAction => {
  return {
    type: AuthActionTypes.LOGIN_FETCH,
    payload: {
      loginStatus: "fetch",
    },
  };
};

export const loginSuccess = (
  user: User,
  accessToken: string,
): LoginSuccessAction => {
  return {
    type: AuthActionTypes.LOGIN_SUCCESS,
    payload: { user, token: accessToken, loginStatus: "success" },
  };
};

export const loginError = (error: string): LoginErrorAction => {
  return {
    type: AuthActionTypes.LOGIN_ERROR,
    payload: { loginStatusError: error, loginStatus: "error" },
  };
};

export const logoutFetch = (): LogoutFetchAction => {
  return {
    type: AuthActionTypes.LOGOUT_FETCH,
    payload: {
      logoutStatus: "fetch",
    },
  };
};

export const logoutSuccess = (): LogoutSuccessAction => {
  return {
    type: AuthActionTypes.LOGOUT_SUCCESS,
    payload: {
      logoutStatus: "success",
      user: undefined,
      token: undefined,
      isAdminMode: false,
    },
  };
};

export const logoutError = (error: string): LogoutErrorAction => {
  return {
    type: AuthActionTypes.LOGOUT_ERROR,
    payload: { logoutStatusError: error, logoutStatus: "error" },
  };
};

export const refreshSessionFetch = (): RefreshSessionFetchAction => {
  return {
    type: AuthActionTypes.REFRESH_SESSION_FETCH,
    payload: {
      refreshSessionStatus: "fetch",
    },
  };
};

export const refreshSessionSuccess = (
  user?: User,
  accessToken?: string,
): RefreshSessionSuccessAction => {
  return {
    type: AuthActionTypes.REFRESH_SESSION_SUCCESS,
    payload: {
      token: accessToken,
      user: user,
      refreshSessionStatus: "success",
    },
  };
};

export const refreshSessionError = (
  error: Error,
): RefreshSessionErrorAction => {
  // eslint-disable-next-line no-console
  console.log(error);
  return {
    type: AuthActionTypes.REFRESH_SESSION_ERROR,
    payload: {
      refreshSessionStatus: "error",
      refreshSessionStatusError: "Error refreshing session",
    },
  };
};

export const setPlanSuccess = (planId: number): SetPlanSuccessAction => {
  return {
    payload: {
      planId: planId,
    },
    type: AuthActionTypes.SET_PLAN_SUCCESS,
  };
};

export const registerFetch = (): RegisterFetchAction => {
  return {
    type: AuthActionTypes.REGISTER_FETCH,
    payload: {
      registerStatus: "fetch",
    },
  };
};

export const registerSuccess = (
  user: User,
  accessToken: string,
): RegisterSuccessAction => {
  return {
    type: AuthActionTypes.REGISTER_SUCCESS,
    payload: { user, token: accessToken, registerStatus: "success" },
  };
};

export const registerError = (error: string): RegisterErrorAction => {
  return {
    type: AuthActionTypes.REGISTER_ERROR,
    payload: { registerStatusError: error, registerStatus: "error" },
  };
};

export const resetPasswordFetch = (): ResetPasswordFetchAction => {
  return {
    type: AuthActionTypes.RESET_PASSWORD_FETCH,
    payload: {
      resetPasswordStatus: "fetch",
    },
  };
};

export const resetPasswordSuccess = (): ResetPasswordSuccessAction => {
  return {
    type: AuthActionTypes.RESET_PASSWORD_SUCCESS,
    payload: { resetPasswordStatus: "success" },
  };
};

export const resetPasswordError = (error: string): ResetPasswordErrorAction => {
  return {
    type: AuthActionTypes.RESET_PASSWORD_ERROR,
    payload: { resetPasswordStatusError: error, resetPasswordStatus: "error" },
  };
};

export const updatePasswordFetch = (): UpdatePasswordFetchAction => {
  return {
    type: AuthActionTypes.UPDATE_PASSWORD_FETCH,
    payload: {
      updatePasswordStatus: "fetch",
    },
  };
};

export const updatePasswordSuccess = (): UpdatePasswordSuccessAction => {
  return {
    type: AuthActionTypes.UPDATE_PASSWORD_SUCCESS,
    payload: { updatePasswordStatus: "success" },
  };
};

export const updatePasswordError = (
  error: string,
): UpdatePasswordErrorAction => {
  return {
    type: AuthActionTypes.UPDATE_PASSWORD_ERROR,
    payload: {
      updatePasswordStatusError: error,
      updatePasswordStatus: "error",
    },
  };
};
