/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual
// application logic in
// a relevant structure within app/javascript and only use these pack files to
// reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to
// the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder
// and reference
// them with the image_pack_tag helper in views
// (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Needed for browser polyfills
import "core-js/stable";
import "regenerator-runtime/runtime";
import "./src/react-bundle";
import "./src/typescript";
import "./src/airbrake";
import areAnyCategoryIdsExclusive from "./src/categories/queries/areAnyCategoryIdsExclusive";
import getCompiledLocations from "./src/map/queries/getCompiledLocations";
import shouldDoGeographicPagination from "./src/map/queries/shouldDoGeographicPagination";
import getCategoryFromId from "./src/categories/queries/getCategoryFromId";
import getDefaultFromDate from "./src/events/queries/getDefaultFromDate";
import getDefaultToDate from "./src/events/queries/getDefaultToDate";
import isPlanMenuItemSelected from "./src/domain/navigation/queries/isPlanMenuItemSelected";
import resetKiosk from "./src/domain/kiosk/commands/resetKiosk";
import enableEditMode from "./src/domain/edit_mode/commands/enableEditMode";
import videojs from "video.js";
require("./src/legacy/map/map");
require("./src/legacy/config/store");
require("./src/legacy/flyoutLoader");
require("./src/legacy/analytics");
require("./src/legacy/events/flyoutLoaded");
require("./src/legacy/events/userChanged");
require("./src/legacy/controllers/commands/showMapItLocation");
require("./src/legacy/controllers/navigationController");
require("./src/legacy/controllers/menuItemsController");
require("./src/legacy/controllers/controls/startPlanControl");
require("./src/legacy/controllers/controls/mapItControl");
require("./src/legacy/controllers/controls/mobile/mapItControl");
require("./src/legacy/controllers/controls/tourMapItControl");
require("./src/legacy/controllers/controls/planItemControl");
require("./src/legacy/controllers/controls/feedPostListItemsControl");
require("./src/legacy/controllers/controls/socialShareControl");
require("./src/legacy/controllers/controls/coverPhotoUploadControl");
require("./src/legacy/controllers/controls/mapRoutingControl");
require("./src/legacy/controllers/controls/mediaFilePlaybackControl");
require("./src/legacy/controllers/controls/adInterpolatorControl");
require("./src/legacy/controllers/planItemListController");
require("./src/legacy/controllers/kioskController");
require("./src/legacy/controllers/modals/registrationModal");
require("./src/legacy/controllers/modals/createModal");
require("./src/legacy/controllers/modals/tutorialModal");
require("./src/legacy/controllers/modals/shareModal");
require("./src/legacy/models/planItem");
require("./src/legacy/models/plan");
require("./src/legacy/models/user");
require("./src/legacy/models/tours");
require("./src/legacy/models/goal");
require("./src/legacy/models/location");
require("./src/legacy/lib/util");
require("./src/legacy/global");
require("./src/legacy/eventHandlers/onCurrentMenuItemChanged");

require("./src/legacy/controllers/tourListController");
require("./src/legacy/controllers/challengeListController");
require("./src/legacy/controllers/goalListController");
require("./src/legacy/controllers/sharedPlanListController");

import lib from "./src/lib";

/* eslint-disable no-undef */
VisitWidget.map.queries = {
  getCompiledLocations,
  shouldDoGeographicPagination,
};
VisitWidget.categories = {
  queries: {
    areAnyCategoryIdsExclusive,
    getCategoryFromId,
  },
};
VisitWidget.events = {
  queries: {
    areAnyCategoryIdsExclusive,
    getDefaultFromDate,
    getDefaultToDate,
  },
};
VisitWidget.editMode = {
  commands: {
    enableEditMode,
  },
};
VisitWidget.kiosk = {
  commands: {
    resetKiosk,
  },
};
VisitWidget.navigation = {
  queries: {
    isPlanMenuItemSelected,
  },
};
VisitWidget.lib = lib;

window.videojs = videojs;

/* eslint-enable no-undef */
