/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  compose,
  createStore,
  applyMiddleware,
  combineReducers,
  Dispatch,
  AnyAction,
} from "redux";
import thunkMiddleware, { ThunkDispatch } from "redux-thunk";
import { authReducer } from "./auth/authReducer";
import { flyoutReducer } from "./flyout/flyoutReducer";
import { menuItemReducer } from "./menuItem/menuItemReducer";
import { planReducer } from "./plan/planReducer";
import { mapReducer } from "./map/mapReducer";
import { RootReducerState } from "./rootReducer";

let globalDispatch: Dispatch;

// create a type that allows dispatch to be called with thunks w/o typing errors
type AppDispatch = ThunkDispatch<RootReducerState, any, AnyAction>;

const store = (_props, _railsContext) => {
  const reducer = combineReducers({
    auth: authReducer,
    flyouts: flyoutReducer,
    menuItem: menuItemReducer,
    plan: planReducer,
    map: mapReducer,
  } as unknown);
  const middleware = [thunkMiddleware];
  const middlewareEnhancer = applyMiddleware(...middleware);
  const enhancers = [middlewareEnhancer];
  const composedEnhancers = compose(...enhancers) as any;

  const result = createStore(reducer, {} as unknown, composedEnhancers);
  globalDispatch = result.dispatch;
  // expose dispatch to legacy js while we convert the app to React
  (window as any).globalDispatch = globalDispatch;
  return result;
};

export { store, globalDispatch, AppDispatch };
