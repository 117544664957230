import { User } from "../apiTypes";
import {
  LoginErrorAction,
  LoginFetchAction,
  LoginSuccessAction,
  LogoutErrorAction,
  LogoutFetchAction,
  LogoutSuccessAction,
  RefreshSessionErrorAction,
  RefreshSessionFetchAction,
  RefreshSessionSuccessAction,
  SetPlanSuccessAction,
  RegisterErrorAction,
  RegisterFetchAction,
  RegisterSuccessAction,
} from "./authActionTypes";

export type FetchStatus = "idle" | "fetch" | "error" | "success";

export interface AuthState {
  user?: User;
  isAdminMode: boolean;
  loginStatus: FetchStatus;
  loginStatusError?: string;
  refreshSessionStatus: FetchStatus;
  refreshSessionStatusError?: string;
  registerStatus: FetchStatus;
  resetPasswordStatus: FetchStatus;
  registerStatusError?: string;
  updatePasswordStatus: FetchStatus;
  updatePasswordStatusError?: string;
  token?: string;
  planId?: number;
}

const initialState = (): AuthState => {
  return {
    user: undefined,
    isAdminMode: VisitWidget.settings.adminModeOn,
    token: undefined,
    loginStatus: "idle",
    refreshSessionStatus: "idle",
    registerStatus: "idle",
    resetPasswordStatus: "idle",
    updatePasswordStatus: "idle",
  };
};

type AuthActions =
  | LoginSuccessAction
  | LoginErrorAction
  | LoginFetchAction
  | LogoutSuccessAction
  | LogoutErrorAction
  | LogoutFetchAction
  | RefreshSessionErrorAction
  | RefreshSessionFetchAction
  | RefreshSessionSuccessAction
  | SetPlanSuccessAction
  | RegisterErrorAction
  | RegisterFetchAction
  | RegisterSuccessAction;

const authReducer = (state = initialState(), action: AuthActions) => {
  if (JSON.stringify(state) === "{}") {
    // ReactOnRails is a hack, and on bootstrap it will randomly lose initial
    // state, so reset it until it stick
    state = initialState();
  }

  const { payload } = action;

  return {
    ...state,
    ...payload,
  };
};

export { authReducer };
