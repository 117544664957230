import React from "react";
import ReactOnRails from "react-on-rails";

import ExclusiveCategoriesDropDown from "./components/lists/controls/ExclusiveCategoriesDropDown";
import EventListHeader from "./components/lists/EventListHeader";
import { GoalRuleInputs } from "./components/goals/GoalRuleInputs";
import KioskResetBanner from "./components/kiosk/KioskResetBanner";
import EntityListHeader from "./components/lists/EntityListHeader";
import Websites from "./components/flyouts/Websites";
import Priority from "./components/flyouts/Priority";
import { TextInput } from "./components/TextInput";
import { Login } from "./modules/account/Login/Login";
import { Register } from "./modules/account/Register/Register";
import { Profile } from "./modules/account/Profile";
import { ResetPassword } from "./modules/account/ResetPassword";
import { store } from "./store/store";
import { App } from "./components/layout/App";
import { Button } from "./components/Button";
import { TestContainer } from "./components/TestContainer";
import { DropdownMenu } from "./components/DropdownMenu";
import { Checkbox } from "./components/Checkbox";
import { Upload } from "./components/Upload";
import { SignOut } from "./modules/account/Profile/mobile/SignOut";
import { Profile as MobileProfile } from "./modules/account/Profile/mobile/Profile";
import { UpdatePassword } from "./modules/account/UpdatePassword/UpdatePassword";
import { Modal } from "./components/Modal";
import { SharedPlanMenuScreen } from "./components/plans/SharedPlanMenuScreen";
import { NotesButton } from "./components/plans/NotesButton/NotesButton";
import { ProximityMessageSetting } from "./modules/entities/ProximityMessageSetting";
import { ChallengeSubmissionConfig } from "./modules/challenges/ChallengeSubmissionConfig";
// This is how react_on_rails can see the components
ReactOnRails.register({
  ExclusiveCategoriesDropDown:
    ExclusiveCategoriesDropDown as React.FunctionComponent,
  EventListHeader: EventListHeader as React.FunctionComponent,
  GoalRuleInputs: GoalRuleInputs as React.FunctionComponent,
  KioskResetBanner: KioskResetBanner as React.FunctionComponent,
  EntityListHeader: EntityListHeader as React.FunctionComponent,
  Websites: Websites as React.FunctionComponent,
  Priority: Priority as React.FunctionComponent,
  TextInput: TextInput as React.FunctionComponent,
  Login: Login as React.FunctionComponent,
  Profile: Profile as React.FunctionComponent,
  App: App as React.FunctionComponent,
  Register: Register as React.FunctionComponent,
  ResetPassword: ResetPassword as React.FunctionComponent,
  Button: Button as React.FunctionComponent,
  TestContainer: TestContainer as React.FunctionComponent,
  DropdownMenu: DropdownMenu as React.FunctionComponent,
  Checkbox: Checkbox as React.FunctionComponent,
  Upload: Upload as React.FunctionComponent,
  SignOut: SignOut as React.FunctionComponent,
  MobileProfile: MobileProfile as React.FunctionComponent,
  UpdatePassword: UpdatePassword as React.FunctionComponent,
  Modal: Modal as React.FunctionComponent,
  SharedPlanMenuScreen: SharedPlanMenuScreen as React.FunctionComponent,
  NotesButton: NotesButton as React.FunctionComponent,
  ProximityMessageSetting: ProximityMessageSetting as React.FunctionComponent,
  ChallengeSubmissionConfig:
    ChallengeSubmissionConfig as React.FunctionComponent,
});

ReactOnRails.registerStore({
  store: store,
});
