import { logout } from "../../../store/visitWidgetApi";
import { removeAllCookies } from "../../../lib/cookies";
import { logoutError, logoutFetch, logoutSuccess } from "../authActionTypes";
import { setLegacyState } from "../helpers/setLegacyState";
import { RootReducerState } from "../../../store/rootReducer";

/*
 * Logout user.
 */
const logoutAction =
  () => async (dispatch, getState: () => RootReducerState) => {
    try {
      dispatch(logoutFetch());
      const isAdminMode = getState().auth.isAdminMode;

      await removeAllCookies();
      await logout();
      setLegacyState({});

      dispatch(logoutSuccess());

      if (isAdminMode) {
        // for now reload if in admin mode so erb rendered views showing admin
        // functionality via server rendering is removed
        window.location.reload();
      } else {
        // temp reload for all just in case, help test issues with cookies
        window.location.reload();
      }
    } catch (error) {
      dispatch(logoutError(error));
    }
  };

export { logoutAction };
