/* eslint-disable */
import { CookieKeys, setCookie } from "../lib/cookies";
import { Plan, PlanItem } from "../lib/apiTypes";
import {
  ChallengeSubmissionConfigDto,
  PlanFullDto,
  PlanItemCreateDto,
  PlanItemDto,
  ProximityMessageDto,
  TourDto,
  TourListItemDto,
  User,
} from "./apiTypes";

const cacheBreak = () => {
  return `cache=${new Date().getTime()}`;
};

let _accessToken: string | undefined = undefined;
const setAuth = (accessToken: string) => {
  _accessToken = accessToken;
};

const getCsrfToken = (): string => {
  return $("meta[name=csrf-token]").prop("content");
};

const getUser = async (): Promise<User | undefined> => {
  try {
    const response = await fetch(
      `${VisitWidget.settings.currentClientApiUrl}/users?${cacheBreak()}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${_accessToken}`,
          "X-CSRF-Token": getCsrfToken(),
        },
      },
    );
    const user: User = await response.json();
    return user;
  } catch (error: any) {
    return;
  }
};

const createAccessToken = async (
  email: string,
  password: string,
): Promise<string> => {
  const path = `/clients/${
    VisitWidget.settings.currentClientId
  }/tokens?${cacheBreak()}`;
  const response = await fetch(
    `${VisitWidget.settings.currentClientApiUrl}${path}`,
    {
      method: "POST",
      body: JSON.stringify({
        email,
        password,
      }),
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "X-CSRF-Token": getCsrfToken(),
        "X-Requested-With": "XMLHttpRequest",
      },
    },
  );

  const responseBody = await response.json();
  if (!response.ok) {
    throw responseBody;
  }

  return responseBody.auth_token as string;
};

const listPlanItems = async (planId: number): Promise<PlanItemDto[]> => {
  const path = `/plans/${planId}/plan_items?${cacheBreak()}`;
  const response = await fetch(
    `${VisitWidget.settings.currentClientApiUrl}${path}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${_accessToken}`,
        "X-CSRF-Token": getCsrfToken(),
      },
    },
  );

  const responseBody = await response.json();
  if (!response.ok) {
    throw responseBody;
  }

  return responseBody as PlanItemDto[];
};

// eslint-disable-next-line react-func/max-lines-per-function, max-statements
const createUser = async ({
  email,
  password,
  name,
  newsletters,
  captcha,
  postalCode,
  planId,
}: {
  email: string;
  password: string;
  name: string;
  newsletters: { id: number }[];
  captcha: string;
  postalCode?: string;
  planId?: string;
}): Promise<User> => {
  const payload = {
    user: {
      email: email,
      password: password,
      name: name,
      locale: I18n.locale,
      newsletters,
    },
  } as Record<string, any>;

  if (!!postalCode) {
    payload.user.postal_code = postalCode;
  }
  if (!!planId) {
    payload.user.plan_id = String(planId);
  }

  payload["g-recaptcha-response"] = captcha;

  const path = `/users?${cacheBreak()}`;
  const response = await fetch(
    `${VisitWidget.settings.currentClientApiUrl}${path}`,
    {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "X-CSRF-Token": getCsrfToken(),
        "Content-Type": "application/json; charset=utf-8",
      },
    },
  );
  const responseBody = await response.json();
  if (!response.ok) {
    throw responseBody;
  }

  return responseBody as User;
};

// eslint-disable-next-line react-func/max-lines-per-function, max-statements
const resetPassword = async ({
  email,
  captcha,
}: {
  email: string;
  captcha: string;
}): Promise<void> => {
  const path = `/users/password?${cacheBreak()}`;
  const response = await fetch(
    `${VisitWidget.settings.currentClientApiUrl}${path}`,
    {
      method: "POST",
      body: JSON.stringify({
        email: email,
        "g-recaptcha-response": captcha,
      }),
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    },
  );
  const responseBody = await response.json();
  if (!response.ok) {
    throw responseBody;
  }
};

const updatePassword = async ({
  newPassword,
  resetPasswordToken,
}: {
  newPassword: string;
  resetPasswordToken: string;
}): Promise<void> => {
  const path = `/users/password?${cacheBreak()}`;
  const response = await fetch(
    `${VisitWidget.settings.currentClientApiUrl}${path}`,
    {
      method: "PATCH",
      body: JSON.stringify({
        password: newPassword,
        // backend used to handle validation of password matching, but now the frontend will
        password_confirmation: newPassword,
        reset_password_token: resetPasswordToken,
      }),
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    },
  );
  const responseBody = await response.json();
  if (!response.ok) {
    throw responseBody;
  }

  await setCookie(CookieKeys.authToken, responseBody.auth_token);
};

const logout = async () => {
  const path = `/users/sign_out?${cacheBreak()}`;
  await fetch(`${VisitWidget.settings.currentClientApiUrl}${path}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      "X-CSRF-Token": getCsrfToken(),
      "X-Requested-With": "XMLHttpRequest",
    },
  });
};

const getPlanMapLocations = async (planId: string) => {
  const path =
    `/clients/${VisitWidget.settings.currentClientId}/cities/` +
    `${VisitWidget.settings.currentCityId}/locations/map_locations` +
    `?type=PlanItem&page=1&plan_id=${planId}&${cacheBreak()}`;

  const result = await fetch(
    `${VisitWidget.settings.currentClientApiUrl}${path}`,
  );
  return result;
};

const listSharedPlans = (categoryIds: number[], pageNumber: number = 1) => {
  return listTours("SharedPlan", categoryIds, pageNumber);
};

const listTours = async (
  type: "SharedPlan" | any,
  categoryIds: number[],
  pageNumber = 1,
): Promise<TourListItemDto[]> => {
  const clientId = VisitWidget.settings.currentClientId;

  // format to match the way legacy category ids are passed, in future use a simpler implementation
  const categoryIdsQueryString = encodeURI(
    categoryIds.map((x) => `category_ids[]=${x}`).join("&"),
  );

  const path = `/clients/${clientId}/tours?page=${pageNumber}&type=${type}&${categoryIdsQueryString}&${cacheBreak()}`;
  const response = await fetch(
    `${VisitWidget.settings.currentClientApiUrl}${path}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${_accessToken}`,
        "X-CSRF-Token": getCsrfToken(),
      },
    },
  );

  const responseBody = await response.json();
  if (!response.ok) {
    throw responseBody;
  }

  return responseBody as TourListItemDto[];
};

const getTourPlanItems = async (tourId: string | number) => {
  const clientId = VisitWidget.settings.currentClientId;
  const path = `/clients/${clientId}/tours/${tourId}/plan_items?${cacheBreak()}`;
  const response = await fetch(
    `${VisitWidget.settings.currentClientApiUrl}${path}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${_accessToken}`,
        "X-CSRF-Token": getCsrfToken(),
      },
    },
  );

  const responseBody = await response.json();
  if (!response.ok) {
    throw responseBody;
  }

  return responseBody as PlanItemDto[];
};

const getTour = async (
  tourId: string | number,
  accessToken?: string,
): Promise<TourDto> => {
  if (!tourId) {
    throw new Error(`Cannot get tour, tourId is undefined.`);
  }
  const clientId = VisitWidget.settings.currentClientId;
  let path = `/clients/${clientId}/tours/${tourId}?${cacheBreak()}`;
  if (accessToken) {
    path += `?access_token=${accessToken}`;
  }
  const response = await fetch(
    `${VisitWidget.settings.currentClientApiUrl}${path}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${_accessToken}`,
        "X-CSRF-Token": getCsrfToken(),
      },
    },
  );

  const responseBody = await response.json();
  if (!response.ok) {
    throw responseBody;
  }

  return responseBody as TourDto;
};

const addPlanItemsToPlan = async (
  planItemIds: number[],
  planId: number,
): Promise<PlanItemCreateDto[]> => {
  const path = `/plans/${planId}/add_plan_items_to_plan?${cacheBreak()}`;
  const payload = { plan_item_ids: planItemIds };

  const response = await fetch(
    `${VisitWidget.settings.currentClientApiUrl}${path}`,
    {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        Authorization: `Bearer ${_accessToken}`,
        "X-CSRF-Token": getCsrfToken(),
        "Content-Type": "application/json; charset=utf-8",
      },
    },
  );

  const responseBody = await response.json();
  if (!response.ok) {
    throw responseBody;
  }
  return responseBody;
};

const addTourToPlan = async (
  tourId: number,
  planId: number,
): Promise<PlanItemCreateDto[]> => {
  const path = `/plans/${planId}/add_tour_to_plan?${cacheBreak()}`;

  const formData = new URLSearchParams();
  formData.append("tour_id", String(tourId));

  const response = await fetch(
    `${VisitWidget.settings.currentClientApiUrl}${path}`,
    {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${_accessToken}`,
        "X-CSRF-Token": getCsrfToken(),
      },
    },
  );

  const responseBody = await response.json();
  if (!response.ok) {
    throw responseBody;
  }
  return responseBody;
};

const removePlanItem = async (
  planItemId: number,
  planId: number,
  type: "tours" | "plans",
): Promise<void> => {
  const path = `/${type}/${planId}/plan_items/${planItemId}?${cacheBreak()}`;
  const response = await fetch(
    `${VisitWidget.settings.currentClientApiUrl}${path}`,
    {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${_accessToken}`,
        "X-CSRF-Token": getCsrfToken(),
      },
    },
  );

  const responseBody = await response.json();
  if (!response.ok) {
    throw responseBody;
  }
};

const duplicateTour = async (
  tourId: number,
): Promise<{ path: string; tour: TourDto }> => {
  const path = `/tours/${tourId}/duplicate?${cacheBreak()}`;
  const response = await fetch(
    `${VisitWidget.settings.currentClientApiUrl}${path}`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${_accessToken}`,
        "X-CSRF-Token": getCsrfToken(),
      },
    },
  );

  const responseBody = await response.json();
  if (!response.ok) {
    throw responseBody;
  }

  return responseBody;
};

const deleteTour = async (tourId: number): Promise<void> => {
  const path = `/tours/${tourId}?${cacheBreak()}`;
  const response = await fetch(
    `${VisitWidget.settings.currentClientApiUrl}${path}`,
    {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${_accessToken}`,
        "X-CSRF-Token": getCsrfToken(),
      },
    },
  );

  const responseBody = await response.json();
  if (!response.ok) {
    throw responseBody;
  }
};

/**
 * Set the notes for a tour (shared plan)
 */
const setPlanNotes = async (tourId: number, notes: string) => {
  // we must figure out if we are updating notes or adding. If updating, must have the note id.
  const tour = await getTour(tourId);
  let notesId = undefined as undefined | number;
  if (tour.notes?.length > 0) {
    notesId = tour.notes[0].id;
  }

  return updateTour(tourId, {
    notes_attributes: [{ body: notes, id: notesId }],
  });
};

/**
 * Set the notes for a plan item
 */
const setPlanItemNotes = async (
  tourId: number,
  planItemId: number | undefined,
  notes: string,
) => {
  // we must figure out if we are updating notes or adding. If updating, must have the note id.
  const tour = await getTour(tourId);

  const planItem = tour.plan_items.find((x) => x.id === planItemId);
  if (!planItem) {
    throw new Error(
      `Cannot set plan item notes for planItemId ${planItem}. No plan item found as part of tour ${tourId}`,
    );
  }

  let notesId = undefined as undefined | number;
  if (planItem.notes?.length > 0) {
    notesId = planItem.notes[0].id;
  }

  return updateTour(tourId, {
    plan_items_attributes: [
      {
        id: planItemId,
        notes_attributes: [
          {
            id: notesId,
            body: notes,
          },
        ],
      },
    ],
  } as any);
};

const updateTour = async (tourId: number, updates: any): Promise<void> => {
  const path = `/tours/${tourId}?${cacheBreak()}`;

  const response = await fetch(
    `${VisitWidget.settings.currentClientApiUrl}${path}`,
    {
      method: "PUT",
      body: JSON.stringify({ tour: updates }),
      headers: {
        Authorization: `Bearer ${_accessToken}`,
        "X-CSRF-Token": getCsrfToken(),
        "Content-Type": "application/json; charset=utf-8",
      },
    },
  );

  const responseBody = await response.json();
  if (!response.ok) {
    throw responseBody;
  }
};

const shareSharedPlan = async (
  tourId: number,
  emailAddresses: string[],
): Promise<void> => {
  const path = `/tours/${tourId}/share?${cacheBreak()}`;
  const response = await fetch(
    `${VisitWidget.settings.currentClientApiUrl}${path}`,
    {
      method: "POST",
      body: JSON.stringify({
        emails: emailAddresses,
      }),
      headers: {
        Authorization: `Bearer ${_accessToken}`,
        "X-CSRF-Token": getCsrfToken(),
        "Content-Type": "application/json; charset=utf-8",
      },
    },
  );

  if (!response.ok) {
    throw new Error("Backend error.");
  }
  return;
};

const getPlan = async (planId: number): Promise<PlanFullDto> => {
  const path = `/plans/${planId}}?${cacheBreak()}`;

  const response = await fetch(
    `${VisitWidget.settings.currentClientApiUrl}${path}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${_accessToken}`,
        "X-CSRF-Token": getCsrfToken(),
        "Content-Type": "application/json; charset=utf-8",
      },
    },
  );

  const responseBody = await response.json();
  if (!response.ok) {
    throw responseBody;
  }

  return responseBody;
};

const createPlan = async (): Promise<Plan> => {
  let path = `/plans?${cacheBreak()}`;
  if (VisitWidget.settings.isKiosk) {
    path += `&kiosk=true`;
  }

  const response = await fetch(
    `${VisitWidget.settings.currentClientApiUrl}${path}`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${_accessToken}`,
        "X-CSRF-Token": getCsrfToken(),
        "Content-Type": "application/json; charset=utf-8",
      },
    },
  );

  const responseBody = await response.json();
  if (!response.ok) {
    throw responseBody;
  }

  return responseBody;
};

const addSharedPlan = async (request: {
  shared_plan: {
    category_ids: string;
    type: "SharedPlan";
    name: string;
    description: string;
    notes_attributes: { body: string }[];
  };
}) => {
  let path = `/shared_plans?${cacheBreak()}`;

  const response = await fetch(
    `${VisitWidget.settings.currentClientApiUrl}${path}`,
    {
      method: "POST",
      body: JSON.stringify(request),
      headers: {
        Authorization: `Bearer ${_accessToken}`,
        "X-CSRF-Token": getCsrfToken(),
        "Content-Type": "application/json; charset=utf-8",
      },
    },
  );

  return response;
};

const adaptPlanDtoToPlan = (plan: PlanFullDto): Plan => {
  return adaptTourOrPlanDtoToPlan(plan, "plan");
};

const adaptTourDtoToPlan = (tour: TourDto): Plan => {
  return adaptTourOrPlanDtoToPlan(tour, "tour");
};

const adaptTourOrPlanDtoToPlan = (
  tourOrPlan: TourDto | PlanFullDto,
  type: "tour" | "plan",
): Plan => {
  const shareUrl =
    type === "tour"
      ? `${window.location.origin}/tours/${tourOrPlan.id}?access_token=${
          (tourOrPlan as TourDto).access_token
        }`
      : `${window.location.origin}/plan?plan_id=${tourOrPlan.id}`;

  return {
    coverPhotoUrl:
      tourOrPlan.plan_items.length > 0
        ? tourOrPlan.plan_items[0].plannable.cover_photo_url
        : "/assets/default_cover_photo.png",
    id: tourOrPlan.id,
    planItems: tourOrPlan.plan_items.map((x) => {
      return {
        coverPhotoUrl: x.plannable.cover_photo_url,
        id: x.id,
        title: x.plannable.name,
        address: x.plannable.location?.address ?? "",
        categoryName:
          x.plannable.category_ids.length > 0
            ? VisitWidget.categories.queries.getCategoryFromId(
                x.plannable.category_ids[0],
              )?.name
            : "",
        latitude: x.plannable.location?.latitude,
        longitude: x.plannable.location?.longitude,
        notes: x.notes?.length > 0 ? x.notes[0].body : "",
        originalDto: x,
      };
    }) as PlanItem[],
    title: tourOrPlan.name,
    notes: tourOrPlan.notes?.length > 0 ? tourOrPlan.notes[0].body : "",
    originalDto: tourOrPlan,
    shareUrl: shareUrl,
  };
};

const getEntityProximityMessageSetting = async ({
  entityId,
  entityType,
}: {
  entityId: number;
  entityType: string;
}): Promise<ProximityMessageDto> => {
  const path = `/${entityType.toLocaleLowerCase()}s/${entityId}/get_proximity_message_setting`;

  const response = await fetch(
    `${VisitWidget.settings.currentClientApiUrl}${path}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${_accessToken}`,
        "X-CSRF-Token": getCsrfToken(),
        "Content-Type": "application/json; charset=utf-8",
      },
    },
  );

  const responseBody = await response.json();
  if (!response.ok) {
    throw responseBody;
  }
  return responseBody as ProximityMessageDto;
};

const updateEntityProximityMessageSetting = async ({
  entityId,
  entityType,
  id,
  updates,
}: {
  entityId: number;
  entityType: string;
  id: number;
  updates: any;
}): Promise<ProximityMessageDto> => {
  const path = `/${entityType.toLocaleLowerCase()}s/${entityId}/set_proximity_message_setting_values`;
  const payload = JSON.stringify({
    [entityType.toLocaleLowerCase()]: {
      proximity_message_setting_attributes: {
        ...updates,
        id: id,
        entity_id: entityId,
        entity_type: entityType,
      },
    },
  });

  const response = await fetch(
    `${VisitWidget.settings.currentClientApiUrl}${path}`,
    {
      method: "PUT",
      body: payload,
      headers: {
        Authorization: `Bearer ${_accessToken}`,
        "X-CSRF-Token": getCsrfToken(),
        "Content-Type": "application/json; charset=utf-8",
      },
    },
  );

  const responseBody = await response.json();
  if (!response.ok) {
    throw responseBody;
  }

  return responseBody as ProximityMessageDto;
};

const getChallengeSubmissionConfig = async (
  challengeId: string | number,
): Promise<ChallengeSubmissionConfigDto> => {
  const clientId = VisitWidget.settings.currentClientId;
  const path = `/clients/${clientId}/tours/${challengeId}/challenge_submission_config?${cacheBreak()}`;
  const response = await fetch(
    `${VisitWidget.settings.currentClientApiUrl}${path}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${_accessToken}`,
        "X-CSRF-Token": getCsrfToken(),
      },
    },
  );

  const responseBody = await response.json();
  if (!response.ok) {
    throw responseBody;
  }

  return responseBody as ChallengeSubmissionConfigDto;
};

const updateChallengeSubmissionConfigs = async (
  tourId: string | number,
  updates: ChallengeSubmissionConfigDto,
): Promise<ChallengeSubmissionConfigDto> => {
  const path = `/tours/${tourId}?${cacheBreak()}`;

  const updateResponse = await fetch(
    `${VisitWidget.settings.currentClientApiUrl}${path}`,
    {
      method: "PUT",
      body: JSON.stringify({ challenge: updates }),
      headers: {
        Authorization: `Bearer ${_accessToken}`,
        "X-CSRF-Token": getCsrfToken(),
        "Content-Type": "application/json; charset=utf-8",
      },
    },
  );

  const updateResponseBody = await updateResponse.json();
  if (!updateResponse.ok && !updateResponse.redirected) {
    let failureBody = "";
    try {
      failureBody = JSON.stringify(updateResponseBody);
    } catch (error: any) {
      failureBody = "Non-JSON response body.";
    }
    let errorResponseBody = Object.assign({}, updates, {
      error: `Update failed: ${failureBody}`,
    });
    return errorResponseBody;
  }

  return getChallengeSubmissionConfig(tourId);
};

export {
  createAccessToken,
  getUser,
  createUser,
  logout,
  listPlanItems,
  setAuth,
  resetPassword,
  updatePassword,
  getTour,
  getPlanMapLocations,
  getTourPlanItems,
  adaptTourDtoToPlan,
  adaptPlanDtoToPlan,
  listTours,
  listSharedPlans,
  removePlanItem,
  addTourToPlan,
  duplicateTour,
  deleteTour,
  setPlanNotes,
  setPlanItemNotes,
  updateTour,
  shareSharedPlan,
  createPlan,
  addPlanItemsToPlan,
  getPlan,
  addSharedPlan,
  getEntityProximityMessageSetting,
  updateEntityProximityMessageSetting,
  getChallengeSubmissionConfig,
  updateChallengeSubmissionConfigs,
};
