import React, { FunctionComponent, useEffect, useState } from "react";
import { refreshSessionAction } from "../../../store/auth/actions/refreshSessionAction";
import { useDispatch, useSelector } from "react-redux";
import { withReduxProvider } from "../../../store/withReduxProvider";
import { createEntityViewAnalytics } from "../../../lib/helpers/createEntityViewAnalytics";
import { Drawer } from "../../flyouts/Drawer";
import { UserSharePlanModal } from "../../plans/UserSharePlanModal/UserSharePlanModal";
import { TourEditGlobalBar } from "../../plans/TourEditGlobalBar";
import { selectIsEditingSharedPlan } from "../../../store/plan/planSelectors";
import { SegmentedCommunications } from "../../../modules/segmentedViews/SegmentedCommunications";
import { CurrentLocationMapPin } from "../../CurrentLocationMapPin";

// A place to coordinate some page load behavior, eventually could be the root
// of a full on React App but requires migrating off react-on-rails which as no
// concept of a app root and instead one-off transpiling in ruby .erb files
const AppBase: FunctionComponent<{}> = () => {
  const dispatch = useDispatch();
  const [userSharedPlan, setUserSharedPlan] = useState<{
    tourId?: number;
    planId?: number;
    accessToken?: string;
  }>(null);
  const isEditingSharedPlan = useSelector(selectIsEditingSharedPlan());

  useEffect(() => {
    // first page load is done via ruby, any subsequent page changes are handled in flyout js
    createEntityViewAnalytics();
  }, []);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dispatch(refreshSessionAction() as any);
  }, [dispatch]);

  useEffect(() => {
    const tourRegex = /^\/tours\/(\d+?)$/;
    const pathname = window.location.pathname;
    const accessToken = new URLSearchParams(window.location.search).get(
      "access_token",
    );

    if (pathname.match(tourRegex) && accessToken !== null) {
      setUserSharedPlan({
        tourId: Number(pathname.match(tourRegex)[1]),
        accessToken: accessToken,
      });
    }

    const planId = new URLSearchParams(window.location.search).get("plan_id");
    if (window.location.pathname === "/plan" && planId) {
      setUserSharedPlan({
        planId: Number(planId),
      });
    }
  }, []);

  return (
    <>
      <Drawer />
      {userSharedPlan && (
        <UserSharePlanModal
          tourId={userSharedPlan.tourId}
          planId={userSharedPlan.planId}
          accessToken={userSharedPlan.accessToken}
        />
      )}
      {isEditingSharedPlan && <TourEditGlobalBar />}
      <SegmentedCommunications />
      <CurrentLocationMapPin />
    </>
  );
};

const App = withReduxProvider(AppBase);
export { App };
