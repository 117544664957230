/* eslint-disable react/jsx-no-target-blank */
import React, { FunctionComponent, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoutAction } from "../../../../store/auth/actions/logoutAction";
import { User } from "../../../../store/apiTypes";
import {
  selectIsAdminMode,
  selectAccessToken,
} from "../../../../store/auth/authSelectors";
import { viewAds } from "../helpers/viewAds";
import translate from "../../../../lib/queries/translate";
import { getIsInIframe } from "../../../../lib/iframe";

// eslint-disable-next-line no-shadow
enum AuthenticatedProfileMenuTestIds {
  viewAdsLink = "view-ads-link",
  switchLocaleLink = "switch-locale-link",
  toggleAdminModeLink = "toggle-admin-mode-link",
  analyticsDashboardLink = "analytics-dashboard-link",
  profileLink = "profile-link",
  signOutLink = "sign-out-link",
}

// eslint-disable-next-line complexity
const AuthenticatedProfileMenu: FunctionComponent<{ user: User }> = ({
  user,
}) => {
  const dispatch = useDispatch();
  const isAdminMode = useSelector(selectIsAdminMode());
  const authToken = useSelector(selectAccessToken());
  const isInIFrame = getIsInIframe();

  const onSignOutClick = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dispatch(logoutAction() as any);
  }, [dispatch]);

  const onViewAdsClick = useCallback((e) => {
    e.preventDefault();
    // viewing ads requires admin mode to be on, thus no need to use redirect
    // to set cookie since it must already exist for admin mode
    viewAds();
  }, []);

  const isAdmin = user?.roles?.indexOf("admin") > -1;

  const isAdsVisible = isAdmin && VisitWidget.settings.adsOn && isAdminMode;

  const canChangeLocale =
    isAdmin && VisitWidget.settings.currentClientLocales.length > 1;

  const adminModeUrl = isInIFrame
    ? `/sessions/redirect?auth_token=${authToken}&type=admin_mode&admin_mode=${
        isAdminMode ? "false" : "true"
      }`
    : `/users/${user.id}/toggle_admin_mode`;

  const changeLocaleUrl = "/users/change_locale";

  return (
    <div className="touch-control">
      <div className="profile dropdown" data-dropdownanchor="true">
        <div className="middle">
          <div className="content">
            <img
              src={user.profile_thumbnail}
              className="facebook-thumbnail"
              alt="profile image"
              referrerPolicy="no-referrer"
            />
            <span className="profile_name">
              {user?.first_name ?? user?.name}
            </span>
          </div>
          <div className="arrow-down"></div>
        </div>
        <ul>
          {isAdmin && (
            <li>
              <a
                data-testid={
                  AuthenticatedProfileMenuTestIds.analyticsDashboardLink
                }
                target={isInIFrame ? "_blank" : "_self"}
                href={`/analytics#authToken=${authToken}`}
                rel="noreferrer">
                Analytics Dashboard
              </a>
            </li>
          )}
          {isAdsVisible && (
            <li>
              <a
                data-testid={AuthenticatedProfileMenuTestIds.viewAdsLink}
                id="view_ads"
                onClick={onViewAdsClick}>
                View Ads
              </a>
            </li>
          )}
          {canChangeLocale &&
            VisitWidget.settings.currentClientLocales.map((locale) => (
              <li key={`switch-locale-${locale}`}>
                <a
                  data-testid={`${AuthenticatedProfileMenuTestIds.switchLocaleLink}-${locale}`}
                  id={`use_locale_${locale}`}
                  target={isInIFrame ? "_blank" : "_self"}
                  href={`${changeLocaleUrl}?locale=${locale}`}>
                  Use &apos;{locale}&apos; Locale
                </a>
              </li>
            ))}
          {isAdmin && (
            <li>
              <a
                data-testid={
                  AuthenticatedProfileMenuTestIds.toggleAdminModeLink
                }
                target={isInIFrame ? "_blank" : "_self"}
                href={adminModeUrl}>
                Turn Admin Mode {isAdminMode ? "Off" : "On"}
              </a>
            </li>
          )}
          <li>
            {/* data-push is picked up by flyout_loader to open flyout */}
            {/* TODO: do not rely on data-push */}
            <a
              data-testid={AuthenticatedProfileMenuTestIds.profileLink}
              data-push="true"
              href={`/users/${user.id}`}>
              Profile
            </a>
          </li>
          <li>
            <a
              data-testid={AuthenticatedProfileMenuTestIds.signOutLink}
              onClick={onSignOutClick}>
              {translate("profile_menu.sign_out")}
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export { AuthenticatedProfileMenu, AuthenticatedProfileMenuTestIds };
