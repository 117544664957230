import React, { FunctionComponent, ReactNode } from "react";
import { Button as AntButton } from "antd";
import "./Button.scss";
import { ConfigProvider } from "../ConfigProvider";

interface MappingProps {
  shape?: "default" | "circle" | "round";
  ghost?: boolean;
  size?: "large" | "middle" | "small";
  color?: string;
  type?: "link" | "text" | "default" | "primary" | "ghost" | "dashed";
  danger?: boolean;
}

interface Mapping {
  pillOutline: MappingProps;
  icon: MappingProps;
  outline: MappingProps;
  primary: MappingProps;
  link: MappingProps;
  text: MappingProps;
  linkSmall: MappingProps;
  graySquare: MappingProps;
  dangerDefault: MappingProps;
}

const mappings: Mapping = {
  pillOutline: {
    shape: "round",
    ghost: true,
    size: "small",
    type: "primary",
  },
  icon: {
    shape: "circle",
    size: "small",
  },
  outline: {
    shape: "default",
    size: "middle",
  },
  primary: {
    shape: "default",
    size: "middle",
    type: "primary",
  },
  link: {
    type: "link",
    shape: "default",
    size: "middle",
  },
  linkSmall: {
    type: "link",
    shape: "default",
    size: "middle",
  },
  text: {
    type: "text",
  },
  graySquare: {
    shape: "default",
    type: "default",
    size: "middle",
  },
  dangerDefault: {
    danger: true,
  },
};

const Button: FunctionComponent<ButtonProps> = ({
  children,
  testID,
  disabled = false,
  onPress,
  href,
  value,
  loading = false,
  icon,
  type,
}) => {
  return (
    <ConfigProvider type="client">
      <AntButton
        disabled={disabled}
        onClick={onPress}
        href={href}
        icon={icon}
        loading={loading}
        value={value}
        data-testid={testID}
        className={`button-${type}`}
        {...mappings[type]}>
        {children}
      </AntButton>
    </ConfigProvider>
  );
};

interface ButtonProps {
  /**
   * Disable clicking the button
   */
  disabled?: boolean;
  /**
   * Test ID for selection in automated testing, sets `data-testid` attribute
   */
  testID?: string;
  /**
   * The button text
   */
  children?: string;
  /**
   * Callback when button is pressed
   */
  onPress?: (event: React.MouseEvent<HTMLElement>) => void;
  /**
   * Location to open when button is pressed
   */
  href?: string;
  /**
   * Value of button
   */
  value?: string;
  /**
   * Whether the loading state of the button should show
   */
  loading?: boolean;
  /**
   * Icon of the button
   */
  icon?: ReactNode;
  /**
   * The style of Visit Widget preset style of button.
   *
   * primary: Use client primary color for background of button.
   * outline: Only a gray outline, and outline will use client primary color on hover.
   * pillOutline: Small pill button with white background, uses client primary color for outline.
   * icon: Icon only, will use current color for color of icon, hover uses client primary color.
   * text: A text button, on hover will show button background of gray.
   * link: Link similar to an html anchor tag.
   */
  type: keyof Mapping;
}

export { Button, ButtonProps };
