import { getIsInIframe } from "./iframe";
import { sendMessage } from "./window-messenger";

// eslint-disable-next-line no-shadow
enum CookieKeys {
  authToken = "authToken",
  planId = "planId",
  tutorialViewed = "tutorial_viewed",
  categoryIds = "category_ids",
}

const setFirstPartyCookie = (
  name: string,
  value: string,
  expirationInDays = 30,
) => {
  const d = new Date();
  d.setTime(d.getTime() + expirationInDays * 24 * 60 * 60 * 1000);
  const expires = "expires=" + d.toUTCString();
  document.cookie = name + "=" + value + ";" + expires + ";path=/";
};

const getFirstPartyCookie = (name: string): string | undefined => {
  return document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop();
};

// check if we are in an iframe, and if so ensure communication with parent
let isSendCookieToParent = undefined;
const getIsSendCookieToParent = async () => {
  if (isSendCookieToParent !== undefined) {
    return isSendCookieToParent;
  }
  if (!getIsInIframe()) {
    isSendCookieToParent = false;
    return isSendCookieToParent;
  }
  try {
    await sendMessage(
      "setCookie",
      {
        name: "communicationSuccess",
        value: "true",
        expirationInDays: -1,
      },
      2000,
    );
    isSendCookieToParent = true;
  } catch (e) {
    isSendCookieToParent = false;
  }

  return isSendCookieToParent;
};

const setCookie = async (
  name: string,
  value: string,
  expirationInDays = 31,
): Promise<void> => {
  // encode value to ensure compatibility with cookies set by the backend
  const encodedValue = encodeURIComponent(value);
  const isUseParent = await getIsSendCookieToParent();
  if (isUseParent) {
    await sendMessage("setCookie", {
      name: name,
      value: encodedValue,
      expirationInDays: expirationInDays,
    });
  }
  // always try and set first party cookie, even if in iframe. If 3rd party
  // cookies are disabled it will silently fail to set
  await new Promise((resolve) => {
    setFirstPartyCookie(name, encodedValue, expirationInDays);
    resolve(undefined);
  });
};

const removeCookie = async (name: string): Promise<void> => {
  const isUseParent = await getIsSendCookieToParent();
  if (isUseParent) {
    await sendMessage("setCookie", {
      name: name,
      value: "",
      expirationInDays: -1,
    });
  } else {
    await new Promise((resolve) => {
      setFirstPartyCookie(name, "", -1);
      resolve(undefined);
    });
  }
};

const removeAllCookies = async (): Promise<void> => {
  await Promise.all(
    Object.values(CookieKeys).map((key: string) => {
      return removeCookie(key);
    }),
  );
};

const getCookieString = async (
  name: CookieKeys,
): Promise<string | undefined> => {
  let result = undefined as string | undefined;
  const isUseParent = await getIsSendCookieToParent();
  if (isUseParent) {
    result = await sendMessage("getCookie", { name: name });
  } else {
    result = await new Promise((resolve) => {
      resolve(getFirstPartyCookie(name));
    });
  }
  return result;
};

const decodeCookie = <T>(
  cookieValueString: string,
  defaultValue: T,
  coerceFunction?: (value: string) => T,
) => {
  if (cookieValueString !== undefined) {
    cookieValueString = decodeURIComponent(cookieValueString);
  }

  let cookieValue = undefined as undefined | T;
  if (coerceFunction && cookieValueString !== undefined) {
    // allow value to be coerced from string to a specific type
    cookieValue = coerceFunction(cookieValueString);
  }

  if (cookieValueString === undefined) {
    // allow defaulting the value if cookie is not set
    cookieValue = defaultValue;
  }

  return cookieValue;
};

const getCookie = async <T>(
  name: CookieKeys,
  defaultValue: T,
  coerceFunction?: (value: string) => T,
): Promise<T> => {
  const cookieValueString = await getCookieString(name);

  let cookieValue = decodeCookie<T>(
    cookieValueString,
    defaultValue,
    coerceFunction,
  );

  if (cookieValue === undefined && cookieValueString) {
    // when no coerce function, return raw string value
    cookieValue = cookieValueString as unknown as T;
  }

  return cookieValue;
};

export { setCookie, getCookie, removeCookie, CookieKeys, removeAllCookies };
