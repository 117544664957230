import { setCookie, CookieKeys } from "../../../lib/cookies";
import { createAccessToken, setAuth } from "../../visitWidgetApi";
import { loginError, loginFetch, loginSuccess } from "../authActionTypes";
import { initializeUser } from "../helpers/initializeUser";
import { buildErrorMessage } from "../helpers/buildErrorMessage";

/*
 * Login user.
 */
const loginAction = (email: string, password: string) => async (dispatch) => {
  try {
    dispatch(loginFetch());

    const authToken = await createAccessToken(email, password);
    setCookie(CookieKeys.authToken, authToken);
    setAuth(authToken);
    const { user } = await initializeUser(authToken, dispatch);

    if (user.roles.indexOf("admin") > -1) {
      // reload the page so admin markup is loaded
      window.location.reload();
      return;
    }

    dispatch(loginSuccess(user, authToken));

    // BRIDGE: hybrid action
    VisitWidget.Modal.close($(".modal"));
  } catch (error) {
    const errorMessage = buildErrorMessage(error);
    alert(errorMessage);
    dispatch(loginError(errorMessage));
  }
};

export { loginAction };
